import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col md="4">
          <h5 className="mb-3">{t(`FOOTER.HEAD1`)}</h5>
          <ul className="list-unstyled">
            <li>
              <Link to="/imprint">{t(`IMPRINT.TITLE`)}</Link>
            </li>
            <li>
              <Link to="/privacy">{t(`PRIVACY.TITLE`)}</Link>
            </li>
            <li>
              <Link to="/terms">{t(`TERMS_AND_CONDITIONS.TITLE`)}</Link>
            </li>
            <li>
              <a href="https://tickettoaster.de/retouren">{t(`SHIPPING_AND_RETOURNS.TITLE`)}</a>
            </li>
          </ul>
        </Col>
        <Col md="4">
          <h5 className="mb-3">{t(`FOOTER.HEAD2`)}</h5>
          <p>{t(`FOOTER.CONTACT_SUPPORT_TEXT`)}</p>
          <p className="font-weight-bolder">
            {t(`FOOTER.SUPPORT_PHONE`)}
            <br />
            <a href="mailto:hallo@tickettoaster.de">{t(`FOOTER.SUPPORT_MAIL`)}</a>
          </p>
        </Col>
        <Col md="4" className="text-sm-right"></Col>
      </Row>
      <Row className="mt-5 d-flex align-items-center">
        <Col md="8" className="align-items-end mt-4 text-left text-muted copyright">
          <p className="small mb-0">
            {/* eslint-disable react/jsx-no-literals */}
            &copy; 2024 <a href="https://www.reaper-entertainment.com/products">REAPER ENTERTAINMENT</a>
          </p>
        </Col>
        <Col md="4" className="d-flex justify-content-end mt-4 copyright-logos">
          <a href="https://tickettoaster.de" target="_blank" rel="noreferrer noopener">
            <img
              src={require('@ttstr/assets/images/Logo_teamtoaster_full_white.png')}
              alt="powered by tickettoaster"
              title="powered by tickettoaster"
              loading="lazy"
              className="img-fluid mr-4"
            />
          </a>
          {/* <a href="https://www.tigermaxcolor.com" target="_blank" rel="noreferrer noopener">
            <img
              src={require('@ttstr/assets/images/tmc_logo_white.png')}
              alt="powered by tigermaxcolor"
              title="powered by tigermaxcolor"
              loading="lazy"
              className="img-fluid"
            />
          </a> */}
          <a
            href="https://www.reaper-entertainment.com/products"
            target="_blank"
            className=""
            rel="noreferrer noopener"
          >
            <img
              src={require('./assets/images/reaper.png')}
              alt="powered by REAPER ENTERTAINMENT"
              title="powered by REAPER ENTERTAINMENT"
              loading="lazy"
              className="img-fluid"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Footer);
