import queryString from 'querystring';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useHistory, useLocation, Link } from 'react-router-dom';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';

import { CategoryNavLinks, useAuth, useIntl } from '@ttstr/components';
import MenuPortal from '@ttstr/components/Navigation/MenuPortal';
import UserLoginModal from '@ttstr/components/Navigation/UserLoginModal';
import Navigation from './Navigation';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { loggedIn, showLogin, setShowLogin, redirectAfterLogin } = useAuth();
  const { language } = useIntl();

  const qs = React.useMemo(() => {
    return queryString.parse(location.search.substring(1));
  }, [location.search]);

  const [mainMenuRef, setMainMenuRef] = React.useState<HTMLDivElement>(); // Could be put into Context
  const [customerMenuRef, setCustomerMenuRef] = React.useState<HTMLLIElement>(); // Could be put into Context
  const [navbarRef, setNavbarRef] = React.useState<HTMLUListElement>(); // Could be put into Context

  const toggleUserLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const isRoot = React.useMemo(() => {
    const matches = matchPath(location.pathname, { path: '/', exact: true });
    return matches && matches.isExact;
  }, [location.pathname]);

  const mainMenu = (
    <>
      <NavItem>
        <NavLink to="/products" exact replace={isRoot} className="nav-link">
          {t(`NAVIGATION.ALL`)}
        </NavLink>
      </NavItem>
      <UncontrolledDropdown className="nav-item" nav inNavbar>
        <DropdownToggle nav caret>
          <span className="">{'Music'}</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem href="/category/4379">{`CD`}</DropdownItem>
          <DropdownItem href="/category/4378">{`VINYL`}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown className="nav-item" nav inNavbar>
        <DropdownToggle nav caret>
          <span className="">{'Merchandise'}</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem href="/category/4371">{`T-Shirts`}</DropdownItem>
          <DropdownItem href="/category/4372">{`Hoodies & Zipper`}</DropdownItem>
          <DropdownItem href="/category/4374">{`Ladies Shirts`}</DropdownItem>
          <DropdownItem href="/category/4382">{`Pants & Shorts`}</DropdownItem>
          <DropdownItem href="/category/4375">{`Accessoires`}</DropdownItem>
          <DropdownItem href="/category/4376">{`Winter Accessoires`}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <NavItem>
        <NavLink to="/category/4381" exact replace={isRoot} className="nav-link">
          {`SALE`}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/category/3672" exact replace={isRoot} className="nav-link">
          {`PRE-ORDER`}
        </NavLink>
      </NavItem>
      {/* <CategoryNavLinks excludeCategories={[3671, 3974, 3649, 3690]} /> */}
      <UncontrolledDropdown className="nav-item" nav inNavbar>
        <DropdownToggle nav caret>
          <span className="">{t(`SHOPCHOOSER.TITLE`)}</span>
        </DropdownToggle>
        <DropdownMenu right>
          {/* <DropdownItem header>{t(`SHOPCHOOSER.TITLE`)}</DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem href="/brand/3466">{t(`SHOPCHOOSER.WARMEN`)}</DropdownItem>
          <DropdownItem href="/brand/4005">{t(`SHOPCHOOSER.MOONSHOT`)}</DropdownItem>
          <DropdownItem href="/brand/4349">{t(`SHOPCHOOSER.MERCURY_CIRCLE`)}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
  return (
    <header id="header" className="d-print-none sticky-top">
      <Navigation
        logo={require('./assets/images/logo.png')}
        logoxs={require('./assets/images/logoxs.png')}
        // fixed="top"
        dark
        cartIcon={<i className="fal fa-shopping-bag" />}
        togglerIcon={
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        }
        togglerClassName="hamburger hamburger--squeeze d-xl-none"
        mainMenuRef={setMainMenuRef}
        customerMenuRef={setCustomerMenuRef}
        navbarRef={setNavbarRef}
        // alwaysCloseMainMenu (causes to autoclose nav on mobile when category dropdowns are opened)
        mainMenuColumnProps={{
          xs: { order: 1 },
        }}
        mainMenuPosition="right"
        showRegister={false}
        showLanguageSwitcher={true}
      />

      {/* Top Banner */}
      {/* {language.includes('de') && (
        // only in this timeframe
        <div className="below-main-navigation container-fluid text-center bg-info py-3 shadow">
          <strong>{t(`PROMO.TOPLINE`)}</strong>
        </div>
      )} */}

      <MenuPortal target={navbarRef}>{mainMenu}</MenuPortal>

      <div className="below-main-navigation container-fluid text-center bg-info py-3 shadow">
        {/* eslint-disable react/jsx-no-literals */}
        <strong>{t(`PROMO.TOPLINE`)}</strong>
        {/* eslint-enable react/jsx-no-literals */}
      </div>

      <MenuPortal target={mainMenuRef}>
        <Nav navbar className="d-xl-none">
          {mainMenu}
        </Nav>
      </MenuPortal>

      <MenuPortal target={customerMenuRef}>
        {loggedIn ? (
          <UncontrolledDropdown>
            <DropdownToggle nav caret title={t(`NAVIGATION.ACCOUNT`)} className="text-info">
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/customer/account" exact>
                {t(`CUSTOMER.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/orders" exact>
                {t(`ORDERS.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/landing-page" exact>
                {t(`CUSTOMER.LANDING_PAGE.TITLE`)}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={NavLink} to="/customer/logout">
                {t('CUSTOMER.LOGOUT')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavItem tag="div">
            <NavLink
              to="#"
              isActive={() => false}
              onClick={(e) => {
                e.preventDefault();
                toggleUserLoginModal();
              }}
              className="nav-link"
              title={t(`NAVIGATION.ACCOUNT`)}
            >
              <i className="fas fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </NavLink>
          </NavItem>
        )}
        <UserLoginModal
          show={showLogin}
          toggle={toggleUserLoginModal}
          onLoginSuccess={() =>
            redirectAfterLogin && history.push(typeof qs.success === 'string' ? qs.success : '/customer/landing-page')
          }
        >
          <div className="alert alert-default p-0">{t(`LOGIN.INTRO`)}</div>
        </UserLoginModal>
      </MenuPortal>
    </header>
  );
};

export default React.memo(Header);
